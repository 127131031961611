(function($) {
    "use strict"; // Start of use strict

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    /*$('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 50)
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });*/

    // Highlight the top nav as scrolling occurs
    /*$('body').scrollspy({
        target: '.navbar-fixed-top',
        offset: 51
    });*/

    // Closes the Responsive Menu on Menu Item Click
    /*$('.navbar-collapse ul li a:not(.dropdown-toggle)').click(function() {
        $('.navbar-toggle:visible').click();
    });*/

    // Offset for Main Navigation
    $('#mainNav').affix({
        offset: {
            top: 100
        }
    })


    
    /* Angebot Anfrage Validation
    ================================================== */

    $("#contact-form-anfrage").validate({
        ignore: [],
        rules: {
            anrede: {
                required: true,
                minlength: 1
            },
            forename: {
                required: true,
                minlength: 2
            },
            name: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true
            },
            company: {
                required: true,
                minlength: 1
            }
        },
        messages: {
            anrede: {
                required: "Bitte wählen Sie Ihre Anrede aus."
            },
            forename: {
                required: "Bitte geben Sie Ihren Vornamen ein.",
                minlength: "Ihr Vorname muss mindestens aus zwei Zeichen bestehen."
            },
            name: {
                required: "Bitte geben Sie Ihren Nachnamen ein.",
                minlength: "Ihr Nachname muss mindestens aus zwei Zeichen bestehen."
            },
            email: "Bitte geben Sie Ihre E-Mail-Adresse ein.",
            company: {
                required: "Bitte geben Sie Ihr Unternehmen ein."
            }
        },
        onfocusout: false,
        invalidHandler: function(form, validator) {
            var errors = validator.numberOfInvalids();
            if (errors) {
                validator.errorList[0].element.focus();
            }
        },

        submitHandler: function(form) {
            $(form).ajaxSubmit({
                target: '#contact-form-result-success',
                type: "POST",
                data: $(form).serialize(),
                url: "form/send.php",
                success: function() {
                    $("#contact-form-anfrage").fadeOut(200, function() {
                        $('.formHeaderAngebot').fadeOut(100);
                        $('#contact-form-result-success').fadeIn(1500);
                        $('#contact-form-result-success').append('<p class="nl-small">Vielen Dank für Ihr Interesse an unserem SmartWeb!</p><p class="nl-small">Wir werden Ihnen umgehend eine Antwort zukommen lassen.</p>');
                    });
                    ga('send', {
                        hitType: 'event',
                        eventCategory: 'Forms',
                        eventAction: 'send',
                        eventLabel: 'Lead'
                    });
                },
                error: function() {
                    $('#contact-form-result-error').fadeIn(1500);
                }
            });
        }
    });
    /*
     * Translated default messages for the jQuery validation plugin.
     * Locale: DE (German, Deutsch)
     */
    $.extend($.validator.messages, {
        required: "Dieses Feld ist ein Pflichtfeld.",
        maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
        minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
        rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
        email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
        url: "Geben Sie bitte eine gültige URL ein.",
        date: "Bitte geben Sie ein gültiges Datum ein.",
        number: "Geben Sie bitte eine Nummer ein.",
        digits: "Geben Sie bitte nur Ziffern ein.",
        equalTo: "Bitte denselben Wert wiederholen.",
        range: $.validator.format("Geben Sie bitte einen Wert zwischen {0} und {1} ein."),
        max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
        min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
        creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein."
    });


    /* EU-COOKIE
    ================================================================================ */
    var eleu = jQuery("#trurnit-eucookie");
    var buttoneu = jQuery("#trurnit-eucookie button");
    if (-1 === document.cookie.indexOf("trurnit-eucookie")) {
        setTimeout(function() { eleu.slideDown("slow"); }, 1000);
    }
    buttoneu.on("click", function() {
        var date = new Date;
        date.setDate(date.getDate() + 365), document.cookie = "trurnit-eucookie=" + escape("hide") + ";path=/;expires=" + date.toGMTString();
        eleu.slideUp("slow");
    });


    /* Parallax Effect
    ================================================================================ */
    $(window).on('load scroll', function() {
        var scrolled = $(this).scrollTop();
        $('.hero-img.parallax').css("background-position", "50% " + (scrolled / 2) + "px")
        $('.intro-text.fadeOut').css({
            'transform': 'translate3d(0, ' + -(scrolled * 0.2) + 'px, 0)', // parallax (20% scroll rate)
            'opacity': 1 - scrolled / 300 // fade out at 400px from top
        });
    });

    /* Initialize Viewport
    ================================================== */
    new WOW().init();


    /*  ACCORDION
    ================================================================================ */

    var CLASS_ACTIVE = 'active'
    var CLASS_OPEN = 'open'
    var $accordion = $('.js-accordion')

    $accordion.on('click', '.js-toggle', function(e) {
        var $item = $(e.currentTarget).parent()
        var $content = $item.find('.js-content')
        var isOpen = $item.hasClass(CLASS_OPEN)

        if (isOpen) {
            // close:
            $item.removeClass(CLASS_ACTIVE)
            $content.slideUp(function() {
                $item.removeClass(CLASS_OPEN)
                $content.show()
            })
        } else {
            // open:
            $item.addClass(CLASS_ACTIVE + ' ' + CLASS_OPEN)
            $content.hide().slideDown()
        }
    });

    /* Magnific Popup
    ================================================================================ */
    $('.inline-popups').magnificPopup({
        delegate: 'a',
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = this.st.el.attr('data-effect');
            }
        },
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });

    $('.popup-youtube').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
        items: {
            src: 'https://www.youtube-nocookie.com/embed/rTImIl70Q7s?rel=0&showinfo=0&autoplay=1'
        },
        // Changes iFrame to support Youtube state changes (so we can close the video when it ends)
        iframe: {
            markup: '<div class="mfp-iframe-scaler">' +
                '<div class="mfp-close"></div>' +
                '<iframe id="player" class="mfp-iframe" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>' +
                '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
            // Converts Youtube links to embeded videos in Magnific popup.
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: 'https://www.youtube-nocookie.com/embed/rTImIl70Q7s?rel=0&showinfo=0&autoplay=1'
                }
            },
            srcAction: 'iframe_src',
        },
        // Tracks Youtube video state changes (so we can close the video when it ends)
        callbacks: {
            open: function() {
                new YT.Player('player', {
                    events: {
                        'onStateChange': onPlayerStateChange
                    }
                });
            }
        },

    });

    // Closes Magnific Popup when Video Ends
    function onPlayerStateChange(event) {
        if (event.data == YT.PlayerState.ENDED) {
            $.magnificPopup.close();
        }
    }



    /* Replace all SVG images with inline SVG
    ================================================================================ */
    jQuery('img.svg').each(function(){
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });

    /* Anmeldeformular
    ================================================== */
    $("#check_contact_phone").on('change', function() {
        if ($(this).is(":checked")) {
            $('.check_phone').slideDown(300);
        }
        if (!$(this).is(":checked")) {
            $('.check_phone').slideUp(300);
        }
    });


    /* CUSTOMER LOGO GRID
    ================================================== */
    /*var customerImageFolder = "img/customer/";

    $.ajax({
        url : customerImageFolder,
        success: function (data) {
            $(data).find("a").attr("href", function (i, val) {
                if( val.match(/\.(jpe?g|png|gif)$/) ) { 
                    $(".customer-logos").append( "<li><a href='#' class='grayscale'><img src='"+ customerImageFolder + val +"'></a></li>");
                } 
            });

            $('.customer-logos img').each(function(){
                var $img = $(this);
                var filename = $img.attr('src')
                $img.attr('alt', filename.substring(0, filename.lastIndexOf('.')));
            });

            $('#ri-grid').gridrotator( {
                rows        : 1,
                columns     : 5,
                animType    : 'scale',
                animSpeed   : 500,
                interval    : 3000,
                step        : 1,

                w1024        : {
                    rows    : 1,
                    columns : 4
                },

                w768        : {
                    rows    : 1,
                    columns : 3
                },

                w480        : {
                    rows    : 1,
                    columns : 2
                },

                w320        : {
                    rows    : 1,
                    columns : 1
                }
            });
        }
    });*/

    jQuery.ajax({            
        url: "get-customer-logos.php", 
        type: "POST",          
        dataType: "HTML", 
            success: function( data ) { 
        jQuery('.customer-logos').append(data);
            $('#ri-grid').gridrotator( {
                    rows        : 1,
                    columns     : 5,
                    animType    : 'scale',
                    animSpeed   : 500,
                    interval    : 3000,
                    step        : 1,

                    w1024        : {
                        rows    : 1,
                        columns : 4
                    },

                    w768        : {
                        rows    : 1,
                        columns : 3
                    },

                    w480        : {
                        rows    : 1,
                        columns : 2
                    },

                    w320        : {
                        rows    : 1,
                        columns : 1
                    }
                });
        },
            error: function(jqXHR, data ) {        
        alert ('Ajax request Failed.');    
        }
        }); 

})(jQuery); // End of use strict